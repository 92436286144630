<template lang='pug'>
	i.text-primary.fontello.fontello-icon-copy.cursor-pointer(
		v-clipboard:copy="text"
		v-clipboard:success="onCopy"
		v-clipboard:error="onCopyError")
</template>
<script>

export default {
	name: 'PwIconCopy',
	components: {
		
	},
	props: {
		text: {
			type: String,
			default: ''
		},
		textSuccess: {
			type: String,
			default: ''
		}
	},
	methods: {
		copy () {
			this.$copyText(this.text)
				.then( e => this.$notify("success", this.textSuccess))
				.catch( e => this.$notify("error", this.$t('error.copyLink'), e));
		},

		onCopy () {
			this.$notify("success", this.textSuccess)
		},
		onCopyError () {
			this.$notify("error", this.$t('error.copyLink'));
		},
	},
	computed: {
		
	},

	created() {
		if ( this.textSuccess == '' ) this.textSuccess = this.$t('success.copyLink');
	}
}
</script>